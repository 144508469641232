
import {PROCESSING_EDGE_KEY_ASSOCIATION} from "../store/constats/processing-edge-const";
import {formatNumbersThousandsNumber} from "./helpers";

function packHandles(widths, containerWidth, edgeCut, handleGap) {
    let effectiveWidth = containerWidth - (2 * edgeCut);  // Учёт обрезки по краям
    let containers = [];

    while (widths.length > 0) {
        let currentContainer = [];
        let currentWidth = 0;

        for (let i = 0; i < widths.length; i++) {
            let handleWidthWithGap = (currentWidth === 0) ? widths[i] : widths[i] + handleGap;
            if (currentWidth + handleWidthWithGap <= effectiveWidth) {
                currentContainer.push(widths[i]);
                currentWidth += handleWidthWithGap;
                widths.splice(i, 1);  // Убираем добавленный элемент
                i--; // Уменьшаем индекс, так как текущий элемент удалён
            }
        }

        containers.push(currentContainer);
    }

    return containers;
}

export const calculateProfilesHandlerPackHelpers = (order, processing_key = 'hand') => {
    let handleGap = order?.production_constants?.hasOwnProperty('production.saw_al_handle') ? Number(order?.production_constants?.['production.saw_al_handle']) : 5;
    let edgeCut = order?.production_constants?.hasOwnProperty('production.trimL_al_handle') ? Number(order?.production_constants?.['production.trimL_al_handle']) : 50;
    if (!edgeCut || !handleGap) {
        throw Error('production.saw_al_handle or production.trimL_al_handle value is not set')
    }
    let part = order.part;
    let part_hands_width = part?.reduce((acc, part) => {
            if (!part?.edge_edit?.[processing_key]) return acc;
            for (const [side, edge] of Object.entries(part?.edge_edit?.[processing_key])) {
                let is_has_goods = !!Number(edge?.goods);
                let product = order?.product?.find(e => Number(e?.id) === Number(part?.product));
                let product_count = product ? Number(product?.count) : 1;
                if (is_has_goods) {
                    let key = `goods_${edge?.goods}`;
                    let goods_val = acc?.goods?.hasOwnProperty(key) && Array.isArray(acc?.goods?.[key]) ? [...acc?.goods[key]] : []
                    let width = ['t', 'b'].includes(side) ? part?.x1 : part?.y1;
                    let width_copy = Number(part?.count) * product_count;
                    let width_counts_part = Array(width_copy).fill(Number(width))?.map(i => Number(width));
                    acc = {
                        ...acc,
                        goods: {
                            ...acc.goods,
                            [key]: [...goods_val, ...width_counts_part]
                        }
                    }
                }
                let is_has_band = !!Number(edge?.band_db_id);
                if (is_has_band) {
                    let key = `band_${edge?.band_db_id}`;
                    let band_val = acc?.band?.hasOwnProperty(key) && Array.isArray(acc?.band?.[key]) ? [...acc?.band[key]] : []
                    let width = ['t', 'b'].includes(side) ? part?.x1 : part?.y1;
                    let width_copy = Number(part?.count) * product_count;
                    let width_counts_part = Array(width_copy).fill(Number(width))?.map(i => Number(width));
                    acc = {
                        ...acc,
                        band: {
                            ...acc.band,
                            [key]: [...band_val, ...width_counts_part]
                        }
                    }
                }
            }

            return acc
        }, {goods: {}, band: {}}
    );
    let goods_band = order?.band?.filter(band => {
        let band_hand = part_hands_width?.band;
        let key = `band_${band?.goods_id}`;
        return band_hand?.[key]
    })?.map(band => {
        let band_hand = part_hands_width?.band;
        let key = `band_${band?.id}`;
        let count = band_hand?.[key]?.reduce((acc, width) => {
            return acc + Number(width)
        }, 0);
        let result_count = (Math.ceil(count / 1000) * 1000) + 1000;

        let count_hand = Math.ceil(result_count / Number(band?.x))
        return {...band, count: count_hand, count_m: result_count}

    })

    let update_goods_count = order?.goods?.filter(goods => goods?.type !== "band")?.map(goods => {
        let key = `goods_${goods?.goods_id}`
        let goods_hand_goods = part_hands_width?.goods;
        let is_has_goods_hand = goods_hand_goods?.hasOwnProperty(key) && Number(goods?.x) && Array.isArray(goods_hand_goods?.[key]);
        if (!is_has_goods_hand) return goods;
        let containerWidth = Number(goods?.x);
        let packedHandles = packHandles(goods_hand_goods[key], containerWidth, edgeCut, handleGap);
        let count = Number(goods?.count) >= Number(packedHandles?.length) ? Number(goods?.count) : Number(packedHandles?.length);
        return {
            ...goods, count: count, count_hand: Number(packedHandles?.length)
        }
    })
    return [...update_goods_count ?? [], ...goods_band ?? []]
}

function nearestHigherMultiple(num, multiplicity) {
    if (num % multiplicity === 0) {
        return num;
    } else {
        return Math.ceil(num / multiplicity) * multiplicity;
    }
}

function calculateHands(part_includes_material, payload_material, getProductCount, order_currency, order) {
    let goods_entry = calculateProfilesHandlerPackHelpers({...order, part: [...part_includes_material]}, 'hand');

    let part_calculate_hand = part_includes_material?.reduce((acc_hand, part) => {
        if (!part?.edge_edit?.hand) return acc_hand;
        for (const [side, edge] of Object.entries(part?.edge_edit?.hand)) {

            let band_count = goods_entry?.find(e => Number(e?.id) === Number(edge?.edge?.band_db_id))?.count ?? null;
            let goods_count = goods_entry?.find(e => Number(e?.goods_id) === Number(edge?.goods))?.count_hand ?? null;
            let initial_hand_data = {
                currency: edge?.currency,
                currency_name: edge?.currency_name,
                edge_edit_handle_id: edge?.edge_edit_handle_id,
                name: edge?.name,
                price_for_sale_in_m2: edge?.price_for_sale_in_m2,
                price_for_sale_in_m2_dif_currencies: edge?.price_for_sale_in_m2_dif_currencies,
                price_for_sale_in_sheet_dif_currencies: edge?.price_for_sale_in_sheet_dif_currencies,
                price_for_sale_in_sheet: edge?.price_for_sale_in_sheet,
                count: 0,
                cost: 0,
                band_db_id: edge?.band_db_id ?? null,
                band_count: band_count,
                goods: edge?.goods,
                goods_count: goods_count,
            }
            // let product = products?.find(e => Number(e?.id) === Number(part?.product));
            let key_hand = `hand_${edge?.['edge_edit_handle_id']}`;
            let hand_key_data = acc_hand?.[key_hand] ? acc_hand?.[key_hand] : {...initial_hand_data}
            let product_count = getProductCount(part)
            let count = Number(part?.count) * product_count;
            acc_hand = {
                ...acc_hand,
                [key_hand]: {
                    ...hand_key_data,
                    count: Number(hand_key_data?.count) + Number(count)
                }
            }


        }
        return acc_hand
    }, {});
    let hands = Object.values(part_calculate_hand);
    if (Array.isArray(hands) && hands?.length) {
        return hands?.map(item => {
            let type = payload_material?.calc_type === "m" ? "m2" : "sheet";
            let currencies = item[`price_for_sale_in_${type}_dif_currencies`]?.find(c => Number(c?.curency_id) === Number(order_currency))
            return {
                ...item,
                price: currencies?.price,
                cost: item?.count * Number(currencies?.price),
            }
        })

    }
    return []
}

function calculatePaint(part_includes_material, payload_material, getProductCount, order_currency) {
    let part_calculate_paint = part_includes_material?.reduce((acc_paint, part) => {
        for (const [key_type, edge_by_side] of Object.entries(part?.edge_edit)) {
            let key_by_type_associate = PROCESSING_EDGE_KEY_ASSOCIATION[key_type];
            if (edge_by_side) {
                for (const [side, edge] of Object.entries(edge_by_side)) {
                    let paint = edge?.edge_edit_handle_painting;
                    if (edge && paint) {
                        let id = edge?.[key_by_type_associate.edge_id_key];
                        let key_paint = `paint_${paint?.edge_edit_handle_painting_id}`;
                        let initial_hand_data = {
                            [key_by_type_associate.edge_id_key]: id,
                            id: id,
                            edge_name: edge?.name,
                            count: 0,
                            cost: 0,
                            ...paint
                        }
                        let product_count = getProductCount(part)
                        // let type = payload_material.calc_type == "sheet" ? "sheet" : "m2";
                        let type = payload_material?.calc_type === "m" ? "m2" : "sheet";
                        let currencies = paint[`price_for_sale_in_${type}_dif_currencies`]?.find(c => Number(c?.curency_id) === Number(order_currency))
                        let hand_key_data = acc_paint?.[key_paint] ? acc_paint?.[key_paint] : {...initial_hand_data}
                        let width = ['t', 'b'].includes(side) ? part?.x1 : part?.y1;
                        let count = (Number(part?.count) * product_count) * Number(width);
                        acc_paint = {
                            ...acc_paint,
                            [key_paint]: {
                                ...hand_key_data,
                                count: Number(hand_key_data?.count) + Number(count),
                                price: currencies?.price,
                                cost: currencies
                            }
                        }
                    }
                }
            }
        }

        return acc_paint
    }, {});
    let paint = Object.values(part_calculate_paint);
    if (Array.isArray(paint) && paint?.length) {
        return paint?.map(item => {
            let type = payload_material?.calc_type === "m" ? "m2" : "sheet";
            let currencies = item[`price_for_sale_in_${type}_dif_currencies`]?.find(c => Number(c?.curency_id) === Number(order_currency))
            let count = formatNumbersThousandsNumber(item?.count / 1000);
            let minimal_quantity = item?.minimal_quantity ? Number(item?.minimal_quantity): 0;
            let multiplicity_neares = nearestHigherMultiple(count, Number(item?.multiplicity));
            let multiplicity_count = Number(multiplicity_neares) >= minimal_quantity ? Number(multiplicity_neares): minimal_quantity;
            return {
                ...item,
                count: count,
                price: currencies?.price,
                cost: formatNumbersThousandsNumber(multiplicity_count * Number(currencies?.price)),
                multiplicity_count: multiplicity_count,
                minimal_quantity: minimal_quantity
            }
        })
    }
    return []
}

// function calculateCountProcessingBySide()
function calculateFaska(part_includes_material, payload_material, getProductCount, order_currency, order) {
    let key_by_type_associate = PROCESSING_EDGE_KEY_ASSOCIATION['faska'];
    let goods_entry = calculateProfilesHandlerPackHelpers({...order, part: [...part_includes_material]}, 'faska');

    let part_calculate = part_includes_material?.reduce((acc, part) => {
        if (!part?.edge_edit?.faska) return acc
        for (const [side, edge] of Object.entries(part?.edge_edit?.faska)) {
            let band_count = goods_entry?.find(e => Number(e?.id) === Number(edge?.edge?.band_db_id))?.count ?? null;
            let id = edge?.[key_by_type_associate.edge_id_key];
            let key = `faska_${edge?.[key_by_type_associate?.edge_id_key]}`;
            let initial_hand_data = {
                [key_by_type_associate.edge_id_key]: id,
                id: id,
                count: 0,
                cost: 0,
                ...edge,
                band_db_id: edge?.band_db_id ?? null,
                band_count: band_count,

            }
            let product_count = getProductCount(part)
            // let type = payload_material.calc_type == "sheet" ? "sheet" : "m2";
            let type = payload_material?.calc_type === "m" ? "m2" : "sheet";
            let currencies = edge[`price_for_sale_in_${type}_dif_currencies`]?.find(c => Number(c?.curency_id) === Number(order_currency))
            let prev_data = acc?.[key] ? acc?.[key] : {...initial_hand_data}
            let width = ['t', 'b'].includes(side) ? part?.x1 : part?.y1;
            let count = (Number(part?.count) * product_count) * Number(width);
            acc = {
                ...acc,
                [key]: {
                    ...prev_data,
                    count: Number(prev_data?.count) + Number(count),
                    price: currencies?.price,
                    cost: currencies
                }
            }
        }
        return acc
    }, {});
    let data = Object.values(part_calculate);
    if (Array.isArray(data) && data?.length) {
        return data?.map(item => {
            let type = payload_material?.calc_type === "m" ? "m2" : "sheet";
            let currencies = item[`price_for_sale_in_${type}_dif_currencies`]?.find(c => Number(c?.curency_id) === Number(order_currency))
            let count = formatNumbersThousandsNumber(item?.count / 1000)
            return {
                ...item,
                count: count,
                price: currencies?.price,
                cost: formatNumbersThousandsNumber(count * Number(currencies?.price)),
            }
        })
    }
    return []
}

function calculateRound(part_includes_material, payload_material, getProductCount, order_currency) {
    let key_by_type_associate = PROCESSING_EDGE_KEY_ASSOCIATION['round'];

    let part_calculate = part_includes_material?.reduce((acc, part) => {
        if (!part?.edge_edit?.round) return acc
        for (const [side, edge] of Object.entries(part?.edge_edit?.round)) {
            let id = edge?.[key_by_type_associate.edge_id_key];
            let key = `round_${edge?.[key_by_type_associate?.edge_id_key]}`;
            let initial_hand_data = {
                [key_by_type_associate.edge_id_key]: id,
                id: id,
                count: 0,
                cost: 0,
                ...edge
            }
            let product_count = getProductCount(part)
            // let type = payload_material.calc_type == "sheet" ? "sheet" : "m2";
            let type = payload_material?.calc_type === "m" ? "m2" : "sheet";
            console.log('type', type)

            let currencies = edge[`price_for_sale_in_${type}_dif_currencies`]?.find(c => Number(c?.curency_id) === Number(order_currency))
            let prev_data = acc?.[key] ? acc?.[key] : {...initial_hand_data}
            let width = ['t', 'b'].includes(side) ? part?.x1 : part?.y1;
            let count = (Number(part?.count) * product_count) * Number(width);
            console.log('round', currencies);
            acc = {
                ...acc,
                [key]: {
                    ...prev_data,
                    count: Number(prev_data?.count) + Number(count),
                    price: currencies?.price,
                    cost: currencies
                }
            }
        }
        return acc
    }, {});
    let data = Object.values(part_calculate);
    if (Array.isArray(data) && data?.length) {
        return data?.map(item => {
            let type = payload_material?.calc_type === "m" ? "m2" : "sheet";
            let currencies = item[`price_for_sale_in_${type}_dif_currencies`]?.find(c => Number(c?.curency_id) === Number(order_currency))
            let count = formatNumbersThousandsNumber(item?.count / 1000)
            return {
                ...item,
                count: count,
                price: currencies?.price,
                cost: formatNumbersThousandsNumber(count * Number(currencies?.price)),
            }
        })
    }
    return []
}

function calculateSrez(part_includes_material, payload_material, getProductCount, order_currency, order) {
    let key_by_type_associate = PROCESSING_EDGE_KEY_ASSOCIATION['srez'];
    let goods_entry = calculateProfilesHandlerPackHelpers({...order, part: [...part_includes_material]}, 'srez');

    let part_calculate = part_includes_material?.reduce((acc, part) => {
        if (!part?.edge_edit?.srez) return acc
        for (const [side, edge] of Object.entries(part?.edge_edit?.srez)) {
            let band_count = goods_entry?.find(e => Number(e?.id) === Number(edge?.band_db_id))?.count ?? null;
            console.log('band_count', band_count)
            let id = edge?.[key_by_type_associate.edge_id_key];
            if (id) {
                let key = `srez_${edge?.[key_by_type_associate?.edge_id_key]}`;
                let initial_hand_data = {
                    [key_by_type_associate.edge_id_key]: id,
                    id: id,
                    count: 0,
                    cost: 0,
                    band_count: band_count,
                    ...edge
                }
                let product_count = getProductCount(part)
                // let type = payload_material.calc_type == "sheet" ? "sheet" : "m2";
                let type = payload_material?.calc_type === "m" ? "m2" : "sheet";
                let currencies = edge[`price_for_sale_in_${type}_dif_currencies`]?.find(c => Number(c?.curency_id) === Number(order_currency))
                let prev_data = acc?.[key] ? acc?.[key] : {...initial_hand_data}
                let width = ['t', 'b'].includes(side) ? part?.x1 : part?.y1;
                let count = (Number(part?.count) * product_count) * Number(width);
                acc = {
                    ...acc,
                    [key]: {
                        ...prev_data,
                        count: Number(prev_data?.count) + Number(count),
                        price: currencies?.price,
                        cost: currencies
                    }
                }
            }
        }
        return acc
    }, {});
    let data = Object.values(part_calculate);
    if (Array.isArray(data) && data?.length) {
        return data?.map(item => {
            let type = payload_material?.calc_type === "m" ? "m2" : "sheet";
            let currencies = item[`price_for_sale_in_${type}_dif_currencies`]?.find(c => Number(c?.curency_id) === Number(order_currency))
            let count = formatNumbersThousandsNumber(item?.count / 1000)
            return {
                ...item,
                count: count,
                price: currencies?.price,
                cost: formatNumbersThousandsNumber(count * Number(currencies?.price)),
            }
        })
    }
    return []
}

function calculateSummByProcessing(processing, currency) {
    return {
        entry: processing,
        summ: processing?.reduce((summ, item) => {
            return Number(item?.amount) + summ
        }, 0),
        currency: currency
    }
}

export const getCalculateShippingProcessingEdge = (order) => {
    try {
        let calculate_material = order.calculate.material;
        let products = order?.product;
        let part = order.part;
        const getProductCount = (part) => {
            let product = products?.find(e => Number(e?.id) === Number(part?.product));
            return product ? Number(product?.count) : 1;
        }
        let calculate_material_hands = calculate_material?.reduce((acc_material, material) => {
                let part_includes_material = part?.filter(e => Number(e?.material) === Number(material?.id) && e?.edge_edit);
                if (!part_includes_material) return acc_material;
                let calc_type = material?.calc_type;
                let x_material = material.x
                let y_material = material.y
                let key_material = `material_${material?.id}`;
                let payload_material = {
                    material: {
                        calc_type: calc_type,
                        x: x_material,
                        y: y_material,
                        id: material.id,
                        name: material?.name
                    },
                }
                let part_calculate_hand = calculateHands(part_includes_material, payload_material?.material, getProductCount, order?.currency, order);
                let part_calculate_paint = calculatePaint(part_includes_material, payload_material?.material, getProductCount, order?.currency);
                let part_calculate_faska = calculateFaska(part_includes_material, payload_material?.material, getProductCount, order?.currency, order);
                let part_calculate_round = calculateRound(part_includes_material, payload_material?.material, getProductCount, order?.currency);
                let part_calculate_srez = calculateSrez(part_includes_material, payload_material?.material, getProductCount, order?.currency, order);

                if (Array.isArray(part_calculate_hand) && part_calculate_hand?.length) {
                    acc_material = {
                        ...acc_material,
                        hand: {
                            ...acc_material.hand,
                            [key_material]: {
                                hand: part_calculate_hand,
                                amount: part_calculate_hand?.reduce((acc, item) => acc + Number(item?.cost), 0),
                                ...payload_material
                            },
                        },
                    }
                }
                if (Array.isArray(part_calculate_paint) && part_calculate_paint?.length) {
                    acc_material = {
                        ...acc_material,
                        paint: {
                            ...acc_material.hand,
                            [key_material]: {
                                paint: part_calculate_paint,
                                amount: part_calculate_paint?.reduce((acc, item) => acc + Number(item?.cost), 0),
                                ...payload_material

                            },
                        },
                    }
                }
                if (Array.isArray(part_calculate_faska) && part_calculate_faska?.length) {
                    acc_material = {
                        ...acc_material,
                        faska: {
                            ...acc_material.faska,
                            [key_material]: {
                                faska: part_calculate_faska,
                                amount: part_calculate_faska?.reduce((acc, item) => acc + Number(item?.cost), 0),
                                ...payload_material

                            },
                        },
                    }
                }
                if (Array.isArray(part_calculate_round) && part_calculate_round?.length) {
                    acc_material = {
                        ...acc_material,
                        round: {
                            ...acc_material.round,
                            [key_material]: {
                                round: part_calculate_round,
                                amount: part_calculate_round?.reduce((acc, item) => acc + Number(item?.cost), 0),
                                ...payload_material

                            },
                        },
                    }
                }
                if (Array.isArray(part_calculate_srez) && part_calculate_srez?.length) {
                    acc_material = {
                        ...acc_material,
                        srez: {
                            ...acc_material.srez,
                            [key_material]: {
                                srez: part_calculate_srez,
                                amount: part_calculate_srez?.reduce((acc, item) => acc + Number(item?.cost), 0),
                                ...payload_material

                            },
                        },
                    }
                }

                return acc_material

            },
            {
                hand: {},
                paint: {},
                faska: {},
                round: {},
                srez: {}
            }
        );


        return {
            handlers_edge: calculateSummByProcessing(Object.values(calculate_material_hands?.hand), order?.currency),
            paints_edge: calculateSummByProcessing(Object.values(calculate_material_hands?.paint), order?.currency),
            faska_edge: calculateSummByProcessing(Object.values(calculate_material_hands?.faska), order?.currency),
            round_edge: calculateSummByProcessing(Object.values(calculate_material_hands?.round), order?.currency),
            srez_edge: calculateSummByProcessing(Object.values(calculate_material_hands?.srez), order?.currency),

        }
    } catch
        (e) {
        console.log(e)
        return {
            handlers_edge: [],
            paints_edge: [],
            faska_edge: [],
            round_edge: [],
            srez_edge: [],
        }
    }
}