import { tools } from './tools.js';

export const edges = {
  middleTextCoord(width, t, fontSize = 7) {
    return width / 2 - t.length * fontSize / 2;
  },
  edges(edgesJson, edgeEdit, partX, partY, partZ, bands, typeSvg) {
    let typeSmallCartLbl = ['small', 'cart', 'lbl'].includes(typeSvg);
    let fontSize = typeSmallCartLbl ? 100 : 30;

    let result = '';
    let edgesKwargs = {
      'dominant-baseline': 'middle',
      'text-anchor': 'middle',
      edge_text: '',
      'font-size': `${fontSize}px`,
      style: tools.whiteTextOutline
    };
    const edgeEditOptions = {
      faska: '[F]',
      hand: '[H]',
      srez: '[S]',
      round: '[R]'
    }

    for (let [side, edge] of Object.entries(edgesJson)) {
      // if (Array.isArray(edge) || edge.db_id === undefined) {
      //   continue;
      // }

      // processing edgeEdit
      const edgeEditParts = [];
      const bandTextParts = [];

      if (typeof edgeEdit === 'object' && edgeEdit !== null) {
        for (let [option, optionShow] of Object.entries(edgeEditOptions)) {
          const optionData = edgeEdit[option];

          if (!tools.objectIsTrue(optionData)) continue;

          const sideData = optionData[side];

          if (tools.objectIsTrue(sideData)) {
            edgeEditParts.push(optionShow)
          }
        }
      }

      if (edgeEditParts.length > 0) {
        const edgeEditText = edgeEditParts.join(' ');
        bandTextParts.push(edgeEditText)
      }


      // processing band
      if (!typeSmallCartLbl && Boolean(edge?.db_id)) {
        let band = bands.filter(b => b.id === edge.db_id);

        if (band.length) {
          //// getting band letter
          band = band[0];

          let bandLetter = ['eva', 'pur', 'laz'].filter(k => edge[k]);

          if (!bandLetter.length) {
            bandLetter = '';
          } else {
            bandLetter = bandLetter[0][0];
          }

          if (edge.pre_joint) {
            bandLetter = bandLetter.toUpperCase();
          }
          let bandInfo = `(${bandLetter}) ${band.z}`;

          if (band.letter) {
            bandInfo = `${band.letter} ${bandInfo}`
          }

          bandTextParts.push(bandInfo);
        }
      }

      if (!bandTextParts.length) continue;

      const bandText = bandTextParts.join(' ');


      if (side === 'l') {
        let x = typeSmallCartLbl ? -70 : -15;
        let y = this.middleTextCoord(partY, bandText, 15);
        result += tools.text(bandText,
          {
            x, y,
            transform: `rotate(-90) translate(-${bandText.length * 15} 0)`,
            ...edgesKwargs
          }
        );
      } else if (side === 't') {
        let x = this.middleTextCoord(partX, bandText, 15)
        let y = typeSmallCartLbl ? -70 : -15;
        result += tools.text(bandText, { x, y, ...edgesKwargs });
      } else if (side === 'r') {
        let x = partX + (typeSmallCartLbl ? 70 : 15);
        let y = this.middleTextCoord(partY, bandText, 15);
        result += tools.text(
          bandText,
          {
            x, y,
            transform: 'rotate(90)',
            ...edgesKwargs,
          }
        );
      } else if (side === 'b') {
        let x = this.middleTextCoord(partX, bandText, 15);
        let y = partY + (typeSmallCartLbl ? 85 : 30);
        result += tools.text(bandText, { x, y, ...edgesKwargs })
      }
    }

    return result;
  }
}
