let _initial_operation = {
    bore: [],
    cut_to: [],
    frez: [],
    hem: [],
    paz: [],
    trough: []
}
let _srez = {
    b: {otstup: "", ugol: "", db_id: ""},
    l: {otstup: "", ugol: "", db_id: ""},
    r: {otstup: "", ugol: "", db_id: ""},
    t: {otstup: "", ugol: "", db_id: ""}
}

export const cleanCncPartMapping = (part) => {
    return {
        ...part,
        srez: _srez,
        operations: {
            ..._initial_operation,
        },
        operations_inputs: {
            ..._initial_operation
        },
        edge_edit: {
            ...part.edge_edit,
            srez: {},
            hand: {},

        }
    }
}